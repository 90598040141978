exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ganzheitlichdenken-js": () => import("./../../../src/pages/ganzheitlichdenken.js" /* webpackChunkName: "component---src-pages-ganzheitlichdenken-js" */),
  "component---src-pages-gleisdorf-js": () => import("./../../../src/pages/gleisdorf.js" /* webpackChunkName: "component---src-pages-gleisdorf-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-praxis-js": () => import("./../../../src/pages/praxis.js" /* webpackChunkName: "component---src-pages-praxis-js" */),
  "component---src-pages-schwerpunkte-js": () => import("./../../../src/pages/schwerpunkte.js" /* webpackChunkName: "component---src-pages-schwerpunkte-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-wannzumneurologen-js": () => import("./../../../src/pages/wannzumneurologen.js" /* webpackChunkName: "component---src-pages-wannzumneurologen-js" */)
}

